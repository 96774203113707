<template>
  <BaseElSelect
    v-model="syncModel"
    :multiple="multiple"
    :multiple-limit="maxSelect || 0"
    filterable
    remote
    clearable
    reserve-keyword
    collapse-tags
    placeholder="搜尋優惠活動"
    no-match-text="無匹配項目"
    no-data-text="暫無數據"
    :remote-method="remoteSearch"
    :loading="loading"
    value-key="id"
    @change="$emit('change')"
    @clear="getActivity"
  >
    <i slot="prefix" class="el-input__icon el-icon-search" />
    <BaseElSelectOption
      v-for="item in productList"
      :key="item.id"
      :label="item.name"
      :value="item"
    />
  </BaseElSelect>
</template>

<script>
import { GetActivity } from '@/api/ecommerce/activity'
import { mapGetters } from 'vuex'
export default {
  name: 'ServiceSearch',
  props: ['model', 'multiple', 'maxSelect'],

  computed: {
    ...mapGetters(['shop']),
    syncModel: {
      get () {
        return this.model
      },

      set (data) {
        this.$emit('update:model', data)
      },
    },
  },

  data: () => ({
    loading: false,
    productList: [],
  }),

  async mounted () {
    await this.getActivity()
  },

  methods: {
    async remoteSearch (query) {
      query = query.trim()
      if (query === '') this.productList = []

      try {
        this.loading = true
        setTimeout(async () => {
          this.getActivity(query)
          this.loading = false
        }, 200)
      } catch (error) {
        console.log(error)
      }
    },

    async getActivity (query = null) {
      try {
        this.productList = []
        setTimeout(async () => {
          const [res, error] = await GetActivity({
            shopId: this.shop,
            name: query || undefined,
            limit: 50,
            isStorewideActivity: false,
          })
          if (error) {
            this.$message.error(error)
            this.productList = []
            return
          }

          this.productList = res
        }, 200)
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
