<template>
  <BaseElSelect
    v-model="syncModel"
    :multiple="multiple"
    :multiple-limit="maxSelect || 0"
    filterable
    remote
    clearable
    reserve-keyword
    collapse-tags
    placeholder="搜尋商品"
    no-match-text="無匹配項目"
    no-data-text="暫無數據"
    :remote-method="remoteSearch"
    :loading="loading"
    value-key="id"
    @change="$emit('change')"
    @clear="getProduct"
  >
    <i slot="prefix" class="el-input__icon el-icon-search" />
    <BaseElSelectOption
      v-for="item in productList"
      :key="item.id"
      :label="item.name"
      :value="item"
    />
  </BaseElSelect>
</template>

<script>
import { GetProduct } from '@/api/ecommerce/product'
import { mapGetters } from 'vuex'
import { filter } from 'lodash'
export default {
  name: 'ProductSelect',
  props: ['model', 'multiple', 'hideActed', 'maxSelect'],

  data: () => ({
    loading: false,
    productList: [],
  }),

  computed: {
    ...mapGetters(['shop']),
    syncModel: {
      get () {
        return this.model
      },

      set (data) {
        this.$emit('update:model', data)
      },
    },
  },

  async mounted () {
    await this.getProduct()
  },

  methods: {
    async remoteSearch (query) {
      query = query.trim()
      if (query === '') this.productList = []

      try {
        this.loading = true
        setTimeout(async () => {
          this.getProduct(query)
          this.loading = false
        }, 200)
      } catch (error) {
        console.log(error)
      }
    },

    async getProduct (query = null) {
      try {
        this.productList = []
        setTimeout(async () => {
          const [res, error] = await GetProduct({
            shopId: this.shop,
            name: query || undefined,
            limit: 50,
          })
          if (error) {
            this.$message.error(error)
            this.productList = []
            return
          }

          this.productList = res

          if (this.hideActed) {
            this.productList = filter(this.productList, {
              EcommerceActivityId: null,
            })
            this.productList.push(...this.syncModel)
          }
        }, 200)
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
