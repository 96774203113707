<template>
  <el-dialog
    :title="dialogTitle"
    :visible="true"
    :close-on-click-modal="false"
    @close="resetForm(), (syncModel.services = [])"
  >
    <section>
      <BaseElForm
        ref="form"
        :model="syncModel"
        label-position="top"
        :rules="formRules"
      >
        <BaseElFormItem label="名稱" prop="name">
          <BaseElInput
            v-model="syncModel.name"
            placeholder="請輸入名稱"
            maxlength="50"
            show-word-limit
          />
        </BaseElFormItem>
        <BaseElFormItem label="代碼" prop="code">
          <BaseElInput
            v-model="syncModel.code"
            placeholder="請輸入代碼"
            maxlength="30"
            show-word-limit
          />
        </BaseElFormItem>
        <BaseElFormItem label="會員使用次數限制" prop="memberUseLimit">
          <BaseElInput v-model="syncModel.memberUseLimit" placeholder="請輸入" />
        </BaseElFormItem>
        <BaseElFormItem label="全店使用次數限制" prop="shopUseLimit">
          <BaseElInput v-model="syncModel.shopUseLimit" placeholder="請輸入" />
        </BaseElFormItem>

        <BaseElFormItem label="商品範圍" prop="shopUseLimit">
          <BaseElSelect
            v-model="syncModel.promoRange"
            placeholder="選擇商品"
            @change="promoRangeChange"
          >
            <BaseElSelectOption
              v-for="range in productRangeTypes"
              :key="range.value"
              :label="range.name"
              :value="range.value"
            />
          </BaseElSelect>
        </BaseElFormItem>

        <BaseElFormItem label="優惠條件" prop="promoRequirements">
          <BaseElSelect
            v-model="syncModel.promoRequirements"
            placeholder="請選擇"
            @change="syncModel.req = ''"
          >
            <BaseElSelectOption
              v-for="req in Object.keys(promoReq)"
              v-show="availableReq(req)"
              :key="req"
              :label="promoReqDict(req).name"
              :value="promoReqDict(req)"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <div v-if="syncModel.promoRequirements !== ''">
          <BaseElFormItem
            :label="
              syncModel.promoRequirements.type === 'quantity' ? '數量' : '金額'
            "
            prop="req"
          >
            <BaseElInput v-model="syncModel.req" placeholder="請輸入" />
          </BaseElFormItem>
        </div>

        <BaseElFormItem label="優惠內容" prop="promoContents">
          <BaseElSelect
            v-model="syncModel.promoContents"
            placeholder="請選擇"
            @change="
              ;(syncModel.contentPercent = ''), (syncModel.contentPrice = '')
            "
          >
            <BaseElSelectOption
              v-for="req in Object.keys(promoContent)"
              v-show="avaliableContent(req)"
              :key="req"
              :label="promoContentDict(req).name"
              :value="promoContentDict(req)"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <div v-if="syncModel.promoContents !== ''">
          <BaseElFormItem
            v-if="syncModel.promoContents.type === 'percent'"
            label="打折"
            prop="contentPercent"
          >
            <BaseElInput
              v-model="syncModel.contentPercent"
              placeholder="請輸入比例 例: 0.9 為打9折"
            />
          </BaseElFormItem>
          <BaseElFormItem
            v-if="syncModel.promoContents.type === 'price'"
            label="金額"
            prop="contentPrice"
          >
            <BaseElInput
              v-model="syncModel.contentPrice"
              placeholder="請輸入金額"
            />
          </BaseElFormItem>
        </div>
        <BaseElFormItem label="是否啟用">
          <BaseElSwitch
            v-model="syncModel.enable"
            active-text="是"
            inactive-text="否"
          />
        </BaseElFormItem>

        <BaseElFormItem
          v-if="syncModel.promoRange === 'product'"
          label="綁定商品"
          prop="productBind"
        >
          <!-- 商品 -->
          <ProductSelect
            v-if="syncModel.promoRange === 'product'"
            :model.sync="syncModel.productBind"
          />
        </BaseElFormItem>
        <!-- 活動 -->
        <BaseElFormItem
          v-if="syncModel.promoRange === 'activity'"
          label="綁定商品"
          prop="activityBind"
        >
          <ActivitySelect
            v-if="syncModel.promoRange === 'activity'"
            :model.sync="syncModel.activityBind"
          />
        </BaseElFormItem>

        <BaseElFormItem label="活動優惠是否無效">
          <BaseElSwitch
            v-model="syncModel.disableProductActivity"
            active-text="是"
            inactive-text="否"
          />
        </BaseElFormItem>
        <BaseElFormItem label="全店優惠是否無效">
          <BaseElSwitch
            v-model="syncModel.disableStorewideActivity"
            active-text="是"
            inactive-text="否"
          />
        </BaseElFormItem>

        <!-- <BaseElFormItem label="排序" prop="order">
            <BaseElInput placeholder="請輸入順序" v-model="syncModel.order" />
          </BaseElFormItem> -->
      </BaseElForm>
    </section>

    <div slot="footer">
      <BaseElButton
        plain
        @click="resetForm(), (showDialog = false), (syncModel.services = [])"
      >
        返回
      </BaseElButton>
      <BaseElButton type="primary" @click="confirm">
        {{
          type === 'create' ? '新增' : '儲存'
        }}
      </BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import ProductSelect from '@/components/Search/ecommerce/ProductSelect.vue'
import ActivitySelect from '@/components/Search/ecommerce/ActivitySelect.vue'
import {
  promoReqConfig,
  promoContentConfig,
  productRangeTypes,
} from '@/config/ecommerce'
import { mapGetters } from 'vuex'
import { dialogTitle } from '@/utils/dialog'
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation/index'
import formUtils from '@/utils/form'

export default {
  name: 'PromoSettingDialog',
  components: { ProductSelect, ActivitySelect },
  props: ['model', 'type'],

  data: () => ({
    promoReq: promoReqConfig,
    promoContent: promoContentConfig,
    promoReqWhiteList: [
      'orderPrice',
      'orderQuantity',
      'activityPrice',
      'activityQuantity',
    ],
    promoContentWhiteList: [
      'orderRebate',
      'orderPercent',
      'deliveryPrice',
      'deliveryRebate',
      'deliveryPercent',
    ],

    formRules: {
      name: [noEmptyRules()],
      code: [noEmptyRules()],
      order: [noEmptyRules('請輸入排序'), isDigitRules(), rangeRules()],
      shopUseLimit: [noEmptyRules(), isDigitRules(), rangeRules()],
      memberUseLimit: [noEmptyRules(), isDigitRules(), rangeRules()],
      promoRequirements: [noEmptyRules()],
      promoContents: [noEmptyRules()],
      req: [noEmptyRules(), isDigitRules(), rangeRules()],
      contentPercent: [noEmptyRules(), isDigitRules(), rangeRules(0, 1)],
      contentPrice: [noEmptyRules(), isDigitRules(), rangeRules()],
      productBind: [noEmptyRules()],
      activityBind: [noEmptyRules()],
    },

    productRangeTypes,
  }),

  computed: {
    ...mapGetters(['shop']),
    syncModel: {
      get () {
        return this.model
      },
      set (value) {
        this.$emit('update:model', value)
      },
    },

    dialogTitle () {
      return dialogTitle(this.type, {
        create: '新增折扣碼',
        update: '編輯折扣碼',
      })
    },

    showRangBinding () {
      return true
    },
  },
  methods: {
    avaliableContent (req) {
      let pass = false
      const range = this.syncModel.promoRange
      if (range === 'all') {
        const content = [
          'orderRebate',
          'orderPercent',
          'deliveryPrice',
          'deliveryRebate',
          'deliveryPercent',
        ]
        pass = content.includes(req)
      } else if (range === 'product') {
        const content = [
          'productRebate',
          'productPercent',
          'orderRebate',
          'orderPercent',
          'deliveryPrice',
          'deliveryRebate',
          'deliveryPercent',
        ]
        pass = content.includes(req)
      } else if (range === 'activity') {
        const content = [
          'productPrice',
          'productRebate',
          'productPercent',
          'orderRebate',
          'orderPercent',
          'deliveryPrice',
          'deliveryRebate',
          'deliveryPercent',
        ]
        pass = content.includes(req)
      }

      return pass
    },
    availableReq (req) {
      let pass = false
      const range = this.syncModel.promoRange
      if (range === 'all') {
        const content = ['orderPrice', 'orderQuantity']
        pass = content.includes(req)
      } else if (range === 'product') {
        const content = ['activityPrice', 'activityQuantity']
        pass = content.includes(req)
      } else if (range === 'activity') {
        const content = ['activityPrice', 'activityQuantity']
        pass = content.includes(req)
      }

      return pass
    },
    promoReqDict (req) {
      return promoReqConfig[req]
    },

    promoContentDict (content) {
      return promoContentConfig[content]
    },

    //= > 檢查表單輸入驗證
    async checkForm () {
      console.log(this.$refs.form)
      return await formUtils.checkForm(this.$refs.form)
    },

    //= > 重置表單
    resetForm () {
      // formUtils.resetForm(this.$refs['form'])
      this.syncModel = {
        isPublic: true,
        name: '',
        order: 100,
        id: '',
        enable: true,
        code: '',
        memberUseLimit: 100,
        shopUseLimit: 100,
        disableProductActivity: false,
        disableStorewideActivity: false,
        requirementLogic: 'OR',
        promoContents: '',
        promoRequirements: '',
        req: '',
        contentPercent: '',
        contentPrice: '',
        productBind: '',
        activityBind: '',
        promoRange: '',
      }

      this.$emit('close')
    },

    async confirm () {
      if (!(await this.checkForm())) return
      this.$emit('confirm')
    },

    promoRangeChange () {
      this.syncModel.promoRequirements = ''
      this.syncModel.promoContents = ''
      this.syncModel.productBind = ''
      this.syncModel.activityBind = ''
    },
  },
}
</script>

<style></style>
